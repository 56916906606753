@import '../../sass/layout';

:local(.footer) {
  text-align: center;
  background-color: #fff;
}

:local(.content) {
  margin: 24px;
}

:local(.innerLayout) {
  min-height: 100vh;
}
